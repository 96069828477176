import { Skeleton, Stack, Typography } from "@mui/material";

export default function ExpenseDetailsSkeleton() {
  return (
    <Stack spacing={2}>
      {[...Array(4)].map((index) => (
        <Stack
          key={index}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">
            <Skeleton width={100} />
          </Typography>
          <Typography variant="h6">
            <Skeleton width={60} />
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
}
