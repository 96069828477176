import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackwardIosIcon from "@mui/icons-material/ArrowBackIos";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  INotificatonPreferences,
  getPreferences,
} from "../api/notificationService";
import { setNotificationPreferences } from "../redux/slices/authSlice";
import { reset } from "../redux/store";
import StorageManager from "../services/storage";
import CardContainer from "./CardContainer";
import ConfirmationDialog from "../components/ConfirmationDialog";
import NotificationSwitch from "../components/NotificationSwitch";
import { isNotificationSupported } from "../common/constants";
import useCheckMobileScreen from "../common/hooks";

const SettingsPage = () => {
  const { t } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [confirmLogoutDialog, setConfirmLogoutDialog] =
    useState<boolean>(false);
  const [showNotificationsOptions, setShowNotificationsOptions] =
    useState<boolean>(true);

  // const { data } = useAPI<INotificatonPreferences>(
  //   getPreferences(StorageManager.getDeviceId())
  // );

  useEffect(() => {
    if (
      !isNotificationSupported ||
      (isMobile && !window.matchMedia("(display-mode: standalone)").matches)
    ) {
      setShowNotificationsOptions(false);
    }
  }, []);

  // useEffect(() => {
  //   dispatch(setNotificationPreferences(data));
  // }, [data]);

  const handleOpenLogoutDialog = () => {
    setConfirmLogoutDialog(true);
  };

  const handleCloseLogoutDialog = () => {
    setConfirmLogoutDialog(false);
  };

  const signout = () => {
    reset();
    StorageManager.clear();
    navigate("/login");
  };
  return (
    <>
      <CardContainer>
        <CardContainer.Header>
          <CardContainer.HeaderContent>
            <CardContainer.HeaderTextItem>
              {t("settings")}
            </CardContainer.HeaderTextItem>
          </CardContainer.HeaderContent>
        </CardContainer.Header>
        <CardContainer.Content>
          <List component="nav">
            {showNotificationsOptions && (
              <ListItem data-test-id="notification" disablePadding>
                <ListItemIcon>
                  <NotificationsActiveIcon />
                </ListItemIcon>
                <ListItemText
                  id="switch-notification"
                  primary={t("settings_notifications")}
                  primaryTypographyProps={{
                    color: "text.primary",
                    lineHeight: 2,
                  }}
                />
                <NotificationSwitch />
              </ListItem>
            )}
            <ListItem
              data-test-id="change-pin"
              disablePadding
              component={Link}
              to="/settings/change-pin"
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("settings_changePin")}
                primaryTypographyProps={{
                  color: "text.primary",
                  lineHeight: 2,
                }}
              />
              {theme.direction === "rtl" ? (
                <ArrowBackwardIosIcon color="action" />
              ) : (
                <ArrowForwardIosIcon color="action" />
              )}
            </ListItem>
          </List>
          <Divider sx={{ my: 2 }} />
          <List component="nav">
            <ListItem
              data-test-id="tc"
              disablePadding
              component={Link}
              to="/terms-and-conditions"
            >
              <ListItemText
                primary={t("terms_termsAndConditions")}
                primaryTypographyProps={{
                  color: "text.primary",
                  lineHeight: 2,
                }}
              />
            </ListItem>
            <ListItem
              data-test-id="logout"
              disablePadding
              sx={{ cursor: "pointer" }}
              onClick={handleOpenLogoutDialog}
            >
              <ListItemText
                primary={t("settings_logout")}
                primaryTypographyProps={{
                  color: "error",
                  lineHeight: 2,
                }}
              />
            </ListItem>
          </List>
        </CardContainer.Content>
      </CardContainer>

      <ConfirmationDialog
        testId="logout-confirmation-dialog"
        open={confirmLogoutDialog}
        title={t("settings_logout_confirmation_title")}
        message={t("settings_logout_confirmation_description")}
        confirmButtonText={t("settings_logout")}
        cancelButtonText={t("cancel")}
        onConfirm={signout}
        onCancel={handleCloseLogoutDialog}
      />
    </>
  );
};

export default SettingsPage;
