/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Self Service API
 * An API which allows a client to interact with the self-service system.
This system exists to manage studies, track participants and oversee expenses.

This API conforms to the JSON:API specification. You can find the specification, [here](https://jsonapi.org/format/).

JSON:API allows clients to pull relationship data within all endpoints using the `include` query parameter.
When this parameter is provided, that relation will be found within the root `included` array located at the end of
each response. 

When updating resources via a `PUT` request, no partial updates are allowed. Please ensure to send all `attributes`
and `relationships`. The only exception to this rule are computed fields - you may omit these from your request body.

This API implements soft deletes for all entities and the soft delete filter is enabled for all endpoints. By
default, no soft delete entities will be shown within list endpoints unless specifically requested. See individual
endpoints for more information.

 * OpenAPI spec version: 0.1.0-alpha
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  MicropaymentAcceptRequestBody,
  MicropaymentAcceptResponseResponse,
  MicropaymentCreateRequestBody,
  MicropaymentCreateResponseResponse,
  MicropaymentDeleteResponseResponse,
  MicropaymentIndexResponseResponse,
  MicropaymentReadResponseResponse,
  MicropaymentRejectRequestBody,
  MicropaymentRejectResponseResponse,
  MicropaymentRestoreResponseResponse
} from '../../model/resources-micropayments.yml'
import type {
  AcceptMicropaymentByIdParams,
  BadRequestErrorResponseResponse,
  CreateNewMicropaymentParams,
  ListAllMicropaymentsParams,
  NotFoundErrorResponseResponse,
  ReadMicropaymentByIdParams,
  RejectMicropaymentByIdParams,
  RestoreMicropaymentByIdParams,
  ServerErrorResponseResponse,
  ValidationErrorResponseResponse
} from '../../model'
import { nmibleInstance } from '../../mutator/axios-instance';
import type { ErrorType, BodyType } from '../../mutator/axios-instance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `micropayments.index` permission.

# Extra information
- This endpoint will return a paginated list of micro payments.

 * @summary List all micro payments.
 */
export const listAllMicropayments = (
    params?: ListAllMicropaymentsParams,
 options?: SecondParameter<typeof nmibleInstance>,signal?: AbortSignal
) => {
      
      
      return nmibleInstance<MicropaymentIndexResponseResponse>(
      {url: `/micropayments`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getListAllMicropaymentsQueryKey = (params?: ListAllMicropaymentsParams,) => {
    return [`/micropayments`, ...(params ? [params]: [])] as const;
    }

    
export const getListAllMicropaymentsQueryOptions = <TData = Awaited<ReturnType<typeof listAllMicropayments>>, TError = ErrorType<BadRequestErrorResponseResponse | ServerErrorResponseResponse>>(params?: ListAllMicropaymentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAllMicropayments>>, TError, TData>>, request?: SecondParameter<typeof nmibleInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListAllMicropaymentsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAllMicropayments>>> = ({ signal }) => listAllMicropayments(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listAllMicropayments>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ListAllMicropaymentsQueryResult = NonNullable<Awaited<ReturnType<typeof listAllMicropayments>>>
export type ListAllMicropaymentsQueryError = ErrorType<BadRequestErrorResponseResponse | ServerErrorResponseResponse>


export function useListAllMicropayments<TData = Awaited<ReturnType<typeof listAllMicropayments>>, TError = ErrorType<BadRequestErrorResponseResponse | ServerErrorResponseResponse>>(
 params: undefined |  ListAllMicropaymentsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAllMicropayments>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof listAllMicropayments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof nmibleInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useListAllMicropayments<TData = Awaited<ReturnType<typeof listAllMicropayments>>, TError = ErrorType<BadRequestErrorResponseResponse | ServerErrorResponseResponse>>(
 params?: ListAllMicropaymentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAllMicropayments>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof listAllMicropayments>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof nmibleInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useListAllMicropayments<TData = Awaited<ReturnType<typeof listAllMicropayments>>, TError = ErrorType<BadRequestErrorResponseResponse | ServerErrorResponseResponse>>(
 params?: ListAllMicropaymentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAllMicropayments>>, TError, TData>>, request?: SecondParameter<typeof nmibleInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary List all micro payments.
 */

export function useListAllMicropayments<TData = Awaited<ReturnType<typeof listAllMicropayments>>, TError = ErrorType<BadRequestErrorResponseResponse | ServerErrorResponseResponse>>(
 params?: ListAllMicropaymentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAllMicropayments>>, TError, TData>>, request?: SecondParameter<typeof nmibleInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListAllMicropaymentsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `micropayments.create` permission.

# Extra information
- This endpoint will return the newly created micro payment.

 * @summary Create a new micro payment.
 */
export const createNewMicropayment = (
    micropaymentCreateRequestBody: BodyType<MicropaymentCreateRequestBody>,
    params?: CreateNewMicropaymentParams,
 options?: SecondParameter<typeof nmibleInstance>,signal?: AbortSignal
) => {
      
      
      return nmibleInstance<MicropaymentCreateResponseResponse>(
      {url: `/micropayments`, method: 'POST',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: micropaymentCreateRequestBody,
        params, signal
    },
      options);
    }
  


export const getCreateNewMicropaymentMutationOptions = <TError = ErrorType<BadRequestErrorResponseResponse | ValidationErrorResponseResponse | ServerErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createNewMicropayment>>, TError,{data: BodyType<MicropaymentCreateRequestBody>;params?: CreateNewMicropaymentParams}, TContext>, request?: SecondParameter<typeof nmibleInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createNewMicropayment>>, TError,{data: BodyType<MicropaymentCreateRequestBody>;params?: CreateNewMicropaymentParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createNewMicropayment>>, {data: BodyType<MicropaymentCreateRequestBody>;params?: CreateNewMicropaymentParams}> = (props) => {
          const {data,params} = props ?? {};

          return  createNewMicropayment(data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateNewMicropaymentMutationResult = NonNullable<Awaited<ReturnType<typeof createNewMicropayment>>>
    export type CreateNewMicropaymentMutationBody = BodyType<MicropaymentCreateRequestBody>
    export type CreateNewMicropaymentMutationError = ErrorType<BadRequestErrorResponseResponse | ValidationErrorResponseResponse | ServerErrorResponseResponse>

    /**
 * @summary Create a new micro payment.
 */
export const useCreateNewMicropayment = <TError = ErrorType<BadRequestErrorResponseResponse | ValidationErrorResponseResponse | ServerErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createNewMicropayment>>, TError,{data: BodyType<MicropaymentCreateRequestBody>;params?: CreateNewMicropaymentParams}, TContext>, request?: SecondParameter<typeof nmibleInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createNewMicropayment>>,
        TError,
        {data: BodyType<MicropaymentCreateRequestBody>;params?: CreateNewMicropaymentParams},
        TContext
      > => {

      const mutationOptions = getCreateNewMicropaymentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `micropayments.read` permission.

# Extra information
- This endpoint will return the requested micro payment.

 * @summary Read micro payment by ID.
 */
export const readMicropaymentById = (
    id: string,
    params?: ReadMicropaymentByIdParams,
 options?: SecondParameter<typeof nmibleInstance>,signal?: AbortSignal
) => {
      
      
      return nmibleInstance<MicropaymentReadResponseResponse>(
      {url: `/micropayments/${id}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getReadMicropaymentByIdQueryKey = (id: string,
    params?: ReadMicropaymentByIdParams,) => {
    return [`/micropayments/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getReadMicropaymentByIdQueryOptions = <TData = Awaited<ReturnType<typeof readMicropaymentById>>, TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>>(id: string,
    params?: ReadMicropaymentByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof readMicropaymentById>>, TError, TData>>, request?: SecondParameter<typeof nmibleInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadMicropaymentByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readMicropaymentById>>> = ({ signal }) => readMicropaymentById(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readMicropaymentById>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type ReadMicropaymentByIdQueryResult = NonNullable<Awaited<ReturnType<typeof readMicropaymentById>>>
export type ReadMicropaymentByIdQueryError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>


export function useReadMicropaymentById<TData = Awaited<ReturnType<typeof readMicropaymentById>>, TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>>(
 id: string,
    params: undefined |  ReadMicropaymentByIdParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof readMicropaymentById>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof readMicropaymentById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof nmibleInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useReadMicropaymentById<TData = Awaited<ReturnType<typeof readMicropaymentById>>, TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>>(
 id: string,
    params?: ReadMicropaymentByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof readMicropaymentById>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof readMicropaymentById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof nmibleInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useReadMicropaymentById<TData = Awaited<ReturnType<typeof readMicropaymentById>>, TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>>(
 id: string,
    params?: ReadMicropaymentByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof readMicropaymentById>>, TError, TData>>, request?: SecondParameter<typeof nmibleInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Read micro payment by ID.
 */

export function useReadMicropaymentById<TData = Awaited<ReturnType<typeof readMicropaymentById>>, TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>>(
 id: string,
    params?: ReadMicropaymentByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof readMicropaymentById>>, TError, TData>>, request?: SecondParameter<typeof nmibleInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getReadMicropaymentByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `micropayments.delete` permission.

# Extra information
- This endpoint will return an empty response body.

 * @summary Delete micro payment by ID.
 */
export const deleteMicropaymentById = (
    id: string,
 options?: SecondParameter<typeof nmibleInstance>,) => {
      
      
      return nmibleInstance<MicropaymentDeleteResponseResponse>(
      {url: `/micropayments/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteMicropaymentByIdMutationOptions = <TError = ErrorType<NotFoundErrorResponseResponse | ValidationErrorResponseResponse | ServerErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMicropaymentById>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof nmibleInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteMicropaymentById>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMicropaymentById>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteMicropaymentById(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteMicropaymentByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMicropaymentById>>>
    
    export type DeleteMicropaymentByIdMutationError = ErrorType<NotFoundErrorResponseResponse | ValidationErrorResponseResponse | ServerErrorResponseResponse>

    /**
 * @summary Delete micro payment by ID.
 */
export const useDeleteMicropaymentById = <TError = ErrorType<NotFoundErrorResponseResponse | ValidationErrorResponseResponse | ServerErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMicropaymentById>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof nmibleInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteMicropaymentById>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteMicropaymentByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `micropayments.restore` permission.

# Extra information
- This endpoint requires no request body.
- This endpoint will return the restored micropayment.

 * @summary Restore micropayment by ID.
 */
export const restoreMicropaymentById = (
    id: string,
    params?: RestoreMicropaymentByIdParams,
 options?: SecondParameter<typeof nmibleInstance>,) => {
      
      
      return nmibleInstance<MicropaymentRestoreResponseResponse>(
      {url: `/micropayments/${id}/restore`, method: 'PUT',
        params
    },
      options);
    }
  


export const getRestoreMicropaymentByIdMutationOptions = <TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ValidationErrorResponseResponse | ServerErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof restoreMicropaymentById>>, TError,{id: string;params?: RestoreMicropaymentByIdParams}, TContext>, request?: SecondParameter<typeof nmibleInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof restoreMicropaymentById>>, TError,{id: string;params?: RestoreMicropaymentByIdParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof restoreMicropaymentById>>, {id: string;params?: RestoreMicropaymentByIdParams}> = (props) => {
          const {id,params} = props ?? {};

          return  restoreMicropaymentById(id,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RestoreMicropaymentByIdMutationResult = NonNullable<Awaited<ReturnType<typeof restoreMicropaymentById>>>
    
    export type RestoreMicropaymentByIdMutationError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ValidationErrorResponseResponse | ServerErrorResponseResponse>

    /**
 * @summary Restore micropayment by ID.
 */
export const useRestoreMicropaymentById = <TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ValidationErrorResponseResponse | ServerErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof restoreMicropaymentById>>, TError,{id: string;params?: RestoreMicropaymentByIdParams}, TContext>, request?: SecondParameter<typeof nmibleInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof restoreMicropaymentById>>,
        TError,
        {id: string;params?: RestoreMicropaymentByIdParams},
        TContext
      > => {

      const mutationOptions = getRestoreMicropaymentByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `micropayments.accept` permission.

# Extra information
- This endpoint will return the accepted micro payment.

 * @summary Accept micro payment by ID.
 */
export const acceptMicropaymentById = (
    id: string,
    micropaymentAcceptRequestBody: BodyType<MicropaymentAcceptRequestBody>,
    params?: AcceptMicropaymentByIdParams,
 options?: SecondParameter<typeof nmibleInstance>,) => {
      
      
      return nmibleInstance<MicropaymentAcceptResponseResponse>(
      {url: `/micropayments/${id}/accept`, method: 'PUT',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: micropaymentAcceptRequestBody,
        params
    },
      options);
    }
  


export const getAcceptMicropaymentByIdMutationOptions = <TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof acceptMicropaymentById>>, TError,{id: string;data: BodyType<MicropaymentAcceptRequestBody>;params?: AcceptMicropaymentByIdParams}, TContext>, request?: SecondParameter<typeof nmibleInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof acceptMicropaymentById>>, TError,{id: string;data: BodyType<MicropaymentAcceptRequestBody>;params?: AcceptMicropaymentByIdParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof acceptMicropaymentById>>, {id: string;data: BodyType<MicropaymentAcceptRequestBody>;params?: AcceptMicropaymentByIdParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  acceptMicropaymentById(id,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AcceptMicropaymentByIdMutationResult = NonNullable<Awaited<ReturnType<typeof acceptMicropaymentById>>>
    export type AcceptMicropaymentByIdMutationBody = BodyType<MicropaymentAcceptRequestBody>
    export type AcceptMicropaymentByIdMutationError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>

    /**
 * @summary Accept micro payment by ID.
 */
export const useAcceptMicropaymentById = <TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof acceptMicropaymentById>>, TError,{id: string;data: BodyType<MicropaymentAcceptRequestBody>;params?: AcceptMicropaymentByIdParams}, TContext>, request?: SecondParameter<typeof nmibleInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof acceptMicropaymentById>>,
        TError,
        {id: string;data: BodyType<MicropaymentAcceptRequestBody>;params?: AcceptMicropaymentByIdParams},
        TContext
      > => {

      const mutationOptions = getAcceptMicropaymentByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * # Authentication/authorization
- 🔐 You must be authenticated.
- 🪪 You must have the `micropayments.reject` permission.

# Extra information
- This endpoint will return the rejected micro payment.

 * @summary Reject micro payment by ID.
 */
export const rejectMicropaymentById = (
    id: string,
    micropaymentRejectRequestBody: BodyType<MicropaymentRejectRequestBody>,
    params?: RejectMicropaymentByIdParams,
 options?: SecondParameter<typeof nmibleInstance>,) => {
      
      
      return nmibleInstance<MicropaymentRejectResponseResponse>(
      {url: `/micropayments/${id}/reject`, method: 'PUT',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: micropaymentRejectRequestBody,
        params
    },
      options);
    }
  


export const getRejectMicropaymentByIdMutationOptions = <TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof rejectMicropaymentById>>, TError,{id: string;data: BodyType<MicropaymentRejectRequestBody>;params?: RejectMicropaymentByIdParams}, TContext>, request?: SecondParameter<typeof nmibleInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof rejectMicropaymentById>>, TError,{id: string;data: BodyType<MicropaymentRejectRequestBody>;params?: RejectMicropaymentByIdParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof rejectMicropaymentById>>, {id: string;data: BodyType<MicropaymentRejectRequestBody>;params?: RejectMicropaymentByIdParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  rejectMicropaymentById(id,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RejectMicropaymentByIdMutationResult = NonNullable<Awaited<ReturnType<typeof rejectMicropaymentById>>>
    export type RejectMicropaymentByIdMutationBody = BodyType<MicropaymentRejectRequestBody>
    export type RejectMicropaymentByIdMutationError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>

    /**
 * @summary Reject micro payment by ID.
 */
export const useRejectMicropaymentById = <TError = ErrorType<BadRequestErrorResponseResponse | NotFoundErrorResponseResponse | ServerErrorResponseResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof rejectMicropaymentById>>, TError,{id: string;data: BodyType<MicropaymentRejectRequestBody>;params?: RejectMicropaymentByIdParams}, TContext>, request?: SecondParameter<typeof nmibleInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof rejectMicropaymentById>>,
        TError,
        {id: string;data: BodyType<MicropaymentRejectRequestBody>;params?: RejectMicropaymentByIdParams},
        TContext
      > => {

      const mutationOptions = getRejectMicropaymentByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    