import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  AlertTitle,
  Direction,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import { onMessage } from "firebase/messaging";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import CustomErrorBoundary from "./common/error/components/CustomErrorBoundary";
import { getLocalizedMessage } from "./common/helpers";
import LanguageSelectorDialog from "./components/AppHeader/LanguageSelectorDialog";
import PWAInstallComponent from "./components/PWAInstallComponent";
import { hideToast, showGlobalMessage } from "./redux/slices/toastSlice";
import { RootState } from "./redux/store";
import AppRoutes from "./router/AppRoutes";
import StorageManager from "./services/storage";
import { messaging } from "./services/firebase";
import { updateNotificationSettings } from "./api/notificationService";
import { isNotificationSupported } from "./common/constants";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { MUIWrapperContext } from "./containers/MUIWrapper";
import { getTheme } from "./themes";

const theme = getTheme();
import { configService } from "./services/configService";

const App = () => {
  const dispatch = useDispatch();
  const toast = useSelector((state: RootState) => state.toast);
  const { i18n, t } = useTranslation();
  const { changeDirection } = useContext(MUIWrapperContext);

  const message = getLocalizedMessage(import.meta.env.VITE_GLOBAL_MESSAGE);

  useEffect(() => {
    if (message && !StorageManager.didDisplayGlobalMessage()) {
      dispatch(showGlobalMessage(message));
    }
  }, []);

  useEffect(() => {
    i18n.on("languageChanged", (lang) => {
      moment.locale(lang);

      document.documentElement.setAttribute("lang", lang);
      const newDirection = i18n.dir(lang) as Direction;
      changeDirection(newDirection);
    });

    return () => {
      i18n.off("languageChanged");
    };
  }, []);

  if (messaging) {
    onMessage(messaging, (payload) => {
      const notificationTitle = payload?.notification?.title || "";

      const notificationOptions = {
        body: payload?.notification?.body || "",
        icon: payload?.notification?.icon || `/icons/icon-512x512.png`,
        data: payload?.data,
        tag: payload?.messageId,
      };

      const notification = new Notification(
        notificationTitle,
        notificationOptions
      );

      notification.onclick = () => {
        const claimId = notification.data && notification.data.claim_id;
        const urlToOpen = claimId ? `/payment/${claimId}/details` : "/payments";

        notification.close();
        window.location.href = urlToOpen; // This works only on the FOCUSED notifications
        // window.open(urlToOpen);
      };
    });
  }

  // This is required to update the UI when the user turns off notifications from the browser itself.
  useEffect(() => {
    if (isNotificationSupported) {
      navigator.permissions
        .query({ name: "notifications" as PermissionName })
        .then((response) => {
          response.onchange = async () => {
            const isGranted = Notification.permission === "granted";

            if (!isGranted) {
              await updateNotificationSettings(false);
            }
          };
        });
    }
  }, []);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(hideToast());
    StorageManager.setDisplayedGlobalMessage();
  };

  const action = (
    <React.Fragment>
      <IconButton
        data-test-id="msg-close-button"
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Router>
      <CustomErrorBoundary>
        <AppRoutes />
        <LanguageSelectorDialog />
      </CustomErrorBoundary>

      {toast.error && (
        <Snackbar
          open={toast.open}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            data-test-id="error"
            onClose={handleClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            <AlertTitle>
              <Typography
                data-test-id="error-title"
                variant="subtitle2"
                sx={{ fontWeight: "bold" }}
              >
                {toast.error.title}
              </Typography>
            </AlertTitle>

            {toast.error.message}
          </Alert>
        </Snackbar>
      )}

      {toast.message && (
        <Snackbar
          data-test-id="global-msg"
          open={toast.open}
          autoHideDuration={toast.autoHide ? 5000 : null}
          onClose={handleClose}
          message={toast.message}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          action={action}
        />
      )}

      {/* NOTE: While description is disabled the dialog falls back to the default text that is in the manifest this makes the translation not working.
        Also if we set the disableDescription to false, the installDescription is forced.*/}
      <PWAInstallComponent
        name={configService.config.clientName}
        disableDescription={true}
        disableScreenshots={true}
        description={t("pwa_install_description", {
          client: configService.config.clientName,
        })}
        // installDescription={" "}
        icon="/icons/icon-512x512.png"
        manifestUrl="manifest.webmanifest"
      />
    </Router>
  );
};

export default App;
