import iqviaTheme from "./iqvia/iqvia-theme";
import nmibleTheme from "./nmible/nmible-theme";
import elligoTheme from "./elligo/elligo-theme";
import ichTheme from "./ich/ich-theme";
import { Client, ClientTheme } from "./theme.types";
import { configService } from "../services/configService";

const clientName = configService.config.clientName.toLowerCase();

export const getTheme = (): ClientTheme => {
  switch (clientName) {
    case Client.Iqvia:
      return iqviaTheme;
    case Client.Nmible:
      return nmibleTheme;
    case Client.Elligo:
      return elligoTheme;
    case Client.Ich:
      return ichTheme;
    default:
      return nmibleTheme;
  }
};
