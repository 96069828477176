import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";

import StorageManager from "../../services/storage";

import { IIdleTimer } from "react-idle-timer";
import { useCountdown, useIdleTimeout } from "../../common/hooks";
import { reset } from "../../redux/store";
import LanguageButton from "./LanguageButton";
import ConfirmationDialog from "../ConfirmationDialog";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  clearMileageClaimData,
  clearReceiptClaimData,
} from "../../redux/slices/claimsSlice";
import { getTheme } from "../../themes";

const theme = getTheme();

/** @type {import("@mui/material").SxProps} */
const styles = {
  appBar: {
    bgcolor: theme.appBarColor,
  },
  appLogo: {
    width: theme.topBarLogoWidth,
    height: "54px",
    marginLeft: 2,
    cursor: "pointer",
  },
};

interface IAppHeaderProps {
  onSidebarCollapse: () => void;
}

const AppHeader: React.FC<IAppHeaderProps> = ({ onSidebarCollapse }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const countdown = useCountdown(59 * 1000);

  useEffect(() => {
    if (location.pathname.indexOf("/submit/") === -1) {
      clearClaimsData();
    }
  }, [location]);

  const [inactivityTimeoutDialog, setInactivityTimeoutDialog] = useState(false);

  const signout = () => {
    reset();
    StorageManager.clear();
    navigate("/login");
  };

  const clearClaimsData = () => {
    dispatch(clearMileageClaimData());
    dispatch(clearReceiptClaimData());
  };

  const onPrompt = () => {
    countdown.reset();
    setInactivityTimeoutDialog(true);
  };

  const onIdle = (event?: Event, idleTimer?: IIdleTimer) => {
    setInactivityTimeoutDialog(false);
    StorageManager.removeFormData();
    StorageManager.removeToken();
    navigate("/login", { state: { from: location, isInactive: true } });
  };

  const { idleTimer } = useIdleTimeout(
    onPrompt,
    onIdle,
    import.meta.env.VITE_INACTIVITY_TIMEOUT_MINUTES
  );

  const cancelTimeout = () => {
    setInactivityTimeoutDialog(false);
    idleTimer.reset();
    countdown.reset();
  };

  return (
    <>
      <AppBar position="sticky" sx={styles.appBar}>
        <Toolbar>
          <IconButton sx={{ color: "lightGray" }} onClick={onSidebarCollapse}>
            <MenuIcon />
          </IconButton>

          <Box
            component={"img"}
            sx={styles.appLogo}
            src={theme.lightLogo}
            onClick={() => navigate("/")}
          />

          <Box sx={{ flexGrow: 1 }} />

          <LanguageButton />
        </Toolbar>
      </AppBar>

      <ConfirmationDialog
        testId="timeout-dialog"
        maxWidth="sm"
        open={inactivityTimeoutDialog}
        title={t("session_to_expire_title")}
        message={
          <span
            dangerouslySetInnerHTML={{
              __html: t("session_to_expire_description_p1", {
                "0": countdown.timeLeftValues.seconds,
              }),
            }}
          />
        }
        confirmButtonText={t("settings_logout")}
        cancelButtonText={t("stay_signed_in")}
        onConfirm={signout}
        onCancel={cancelTimeout}
      />
    </>
  );
};

export default AppHeader;
