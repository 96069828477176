import { Stars as RewardIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { showToast } from "../redux/slices/toastSlice";
import { formatISODate } from "../types/common";
import CardContainer from "./CardContainer";
import { useListAllTaxForms } from "../api/client/tax-forms/tax-forms";
import { hasCompletedTaxForm } from "../common/helpers";
import TaxAlert from "./Tax/TaxAlert";
import ExpenseDetailsSkeleton from "../components/ExpenseDetailsSkeleton";
import {
  useAcceptMicropaymentById,
  useReadMicropaymentById,
  useRejectMicropaymentById,
} from "../api/client/micropayments/micropayments";

const MicropaymentDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [rejectionReasonError, setRejectionReasonError] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { showBoundary } = useErrorBoundary();
  const { data, isLoading, isFetching, error, isError } =
    useReadMicropaymentById(id!);

  if (isError) {
    console.log(error);
    showBoundary(error);
  }

  if (!data?.data && !isLoading) return <Navigate to="/payments" />;

  const expense = data?.data;

  const getStatusColor = (status: string) => {
    switch (status) {
      case "approved":
      case "accepted":
        return "success";
      case "rejected":
        return "error";
      default:
        return "warning";
    }
  };

  const {
    data: taxFormsData,
    isPending: isLoadingTaxForms,
    error: taxFormsError,
  } = useListAllTaxForms();

  const completedTaxForm = hasCompletedTaxForm(taxFormsData?.data ?? []);

  const queryClient = useQueryClient();

  const { mutate: sendAcceptMicropaymentRequest, isPending: accepting } =
    useAcceptMicropaymentById({
      mutation: {
        onError: (error, variables, context) => {
          showBoundary(error);
        },
        onSuccess: (data, variables, context) => {
          queryClient.removeQueries({
            queryKey: ["/micropayments"],
          });
          const id = data.data?.id;
          queryClient.removeQueries({
            queryKey: [`/micropayments/${id}`],
          });

          dispatch(showToast(t("claim_micropayment_approvedToastMessage")));
          navigate("/payments");
        },
      },
    });

  const { mutate: sendRejectMicropaymentRequest, isPending: rejecting } =
    useRejectMicropaymentById({
      mutation: {
        onError: (error, variables, context) => {
          showBoundary(error);
        },
        onSuccess: (data, variables, context) => {
          queryClient.removeQueries({
            queryKey: ["/micropayments"],
          });
          const id = data.data?.id;
          queryClient.removeQueries({
            queryKey: [`/micropayments/${id}`],
          });
          dispatch(showToast(t("claim_micropayment_rejectedToastMessage")));
          navigate("/payments");
        },
      },
    });

  const handleAccept = async () => {
    sendAcceptMicropaymentRequest({
      id: id!,
      data: {
        data: {
          type: "micropayments",
          id: id!,
        },
      },
    });
  };
  const handleReject = async () => {
    setOpenDialog(true);
  };

  function confirmReject() {
    if (!rejectionReason.trim()) {
      setRejectionReasonError(true);
      return;
    }

    sendRejectMicropaymentRequest({
      id: id!,
      data: {
        data: {
          type: "micropayments",
          id: id!,
          attributes: {
            rejectionReason: rejectionReason.trim(),
          },
        },
      },
    });
  }

  if (!id) return null;
  const isPendingExpense =
    expense?.attributes.status.toLowerCase() === "pending";

  return (
    <>
      {isPendingExpense && (
        <TaxAlert
          taxForms={taxFormsData?.data ?? []}
          hasPendingStipends={true}
        />
      )}
      <CardContainer isLoading={isLoading}>
        <CardContainer.Header>
          <CardContainer.Icon>
            <Avatar>
              <RewardIcon />
            </Avatar>
          </CardContainer.Icon>
          <CardContainer.HeaderContent>
            <CardContainer.HeaderTextItem>
              {t("micropayment")}
            </CardContainer.HeaderTextItem>
            <CardContainer.HeaderTextItem>
              {t("claimDetail_claimWithId", { "0": expense?.id })}
            </CardContainer.HeaderTextItem>
          </CardContainer.HeaderContent>
        </CardContainer.Header>
        <CardContainer.Content>
          {isLoading || !expense ? (
            <ExpenseDetailsSkeleton />
          ) : (
            <Stack spacing={2}>
              <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
                <Typography variant="h6">{t("date")}</Typography>
                <Typography variant="h6">
                  {formatISODate(expense.attributes.createdAt!, "date")}
                </Typography>
              </Stack>

              <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
                <Typography variant="h6">{t("mileageReview_Total")}</Typography>
                <Typography variant="h6">
                  ${expense.attributes.amount}
                </Typography>
              </Stack>

              <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
                <Typography variant="h6">{t("status")}</Typography>
                {isFetching ? (
                  <Chip sx={{ width: "80px" }} color="default" />
                ) : (
                  <Chip
                    label={t(expense.attributes.status ?? "")}
                    color={
                      getStatusColor(expense.attributes.status ?? "") as
                        | "success"
                        | "error"
                        | "warning"
                    }
                  />
                )}
              </Stack>
            </Stack>
          )}
          {isPendingExpense && (
            <>
              {(completedTaxForm || isFetching || isLoadingTaxForms) && (
                <Divider sx={{ my: 2 }} />
              )}
              <Stack direction={"row"} justifyContent={"end"} gap={2}>
                {isFetching || isLoadingTaxForms ? (
                  <>
                    <Skeleton
                      variant="rounded"
                      width={"100px"}
                      height={"42.25px"}
                    />
                    <Skeleton
                      variant="rounded"
                      width={"100px"}
                      height={"42.25px"}
                    />
                  </>
                ) : completedTaxForm ? (
                  <>
                    <Button
                      color="error"
                      variant="outlined"
                      data-test-id="reject-button"
                      type="button"
                      size="large"
                      onClick={handleReject}
                    >
                      {t("reject")}
                    </Button>
                    <LoadingButton
                      data-test-id="accept-button"
                      size="large"
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={handleAccept}
                      disabled={accepting}
                      loading={accepting}
                    >
                      {t("accept")}
                    </LoadingButton>
                  </>
                ) : null}
              </Stack>
            </>
          )}
        </CardContainer.Content>
      </CardContainer>
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setRejectionReason("");
        }}
      >
        <DialogTitle>{t("reject_micropayment")}</DialogTitle>
        <DialogContent sx={{ width: { sm: "450px", xs: "300px" } }}>
          <TextField
            autoFocus
            sx={{ my: 1 }}
            id="rejection-reason"
            label={t("claimDetail_rejectionReason")}
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            error={rejectionReasonError}
            value={rejectionReason}
            onChange={(e) => {
              setRejectionReason(e.target.value);
              if (e.target.value.trim()) setRejectionReasonError(false);
            }}
          />
        </DialogContent>
        <DialogActions sx={{ mx: 2, mb: 1.5 }}>
          <LoadingButton
            onClick={() => {
              setOpenDialog(false);
              setRejectionReason("");
            }}
            sx={{ color: "gray" }}
          >
            {t("cancel")}
          </LoadingButton>
          <LoadingButton
            onClick={confirmReject}
            color="error"
            variant="contained"
            loading={rejecting}
          >
            {t("reject")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MicropaymentDetails;
