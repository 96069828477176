import i18n from "i18next";

const amazonLink = import.meta.env.VITE_AMAZON_LINK;

export const getTermsAndConditionsLinks = () => {
  let language = i18n.language.toLowerCase();

  let summaryLink = `${amazonLink}/${language}/summary.html`;
  let pdfLink = `${amazonLink}/${language}/tc.pdf`;

  return {
    pdf: pdfLink,
    content: summaryLink,
  };
};
