import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import { configService, SupportMethod } from "../services/configService";

interface ForgetPinButtonProps {
  title?: string;
}

const ForgetPinButton: React.FC<ForgetPinButtonProps> = ({ title }) => {
  const { t } = useTranslation();
  const [openForgottenPinDialog, setOpenForgottenPinDialog] =
    useState<boolean>(false);

  const supportMethod = configService.config.supportMethod;
  const supportContact = configService.supportContact();

  const handleConfirm = () => {
    if (supportMethod === SupportMethod.email) {
      window.location.href = `mailto:${supportContact}`;
    } else if (supportMethod === SupportMethod.telephone) {
      window.location.href = `tel:${supportContact}`;
    }
  };

  function supportText(): string {
    const supportKey =
      supportMethod === SupportMethod.email
        ? "help_emailSupport"
        : supportMethod === SupportMethod.telephone
        ? "help_callSupport"
        : "";

    const supportText = t(supportKey, {
      client: configService.config.clientName,
      email: supportContact,
    });
    return supportText;
  }

  if (supportMethod === SupportMethod.none) return null;

  return (
    <>
      {/* Button to trigger dialog */}
      <Typography align="center">
        <Button
          data-test-id="forget-pin-button"
          variant="text"
          color="primary"
          size="small"
          sx={{ pl: 2, pr: 2 }}
          onClick={() => setOpenForgottenPinDialog(true)}
        >
          {title ? title : t("changePin_forgetPin")}
        </Button>
      </Typography>

      {/* Forgotten PIN Dialog */}
      <ConfirmationDialog
        testId="forgotten-pin-dialog"
        maxWidth="sm"
        open={openForgottenPinDialog}
        title={t("auth_forgotPinAlertTitle")}
        message={
          <Typography
            data-test-id="support-description"
            fontWeight="500"
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: supportText().replace(
                supportContact,
                `<a dir="ltr" href="tel:${supportContact}">${supportContact}</a>`
              ),
            }}
          ></Typography>
        }
        confirmButtonText={
          supportMethod === SupportMethod.email
            ? t("email_support")
            : t("call_support")
        }
        cancelButtonText={t("button_close")}
        onConfirm={handleConfirm}
        onCancel={() => setOpenForgottenPinDialog(false)}
      />
    </>
  );
};

export default ForgetPinButton;
