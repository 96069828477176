import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  // ListItemIcon,
  ListItemText,
  useTheme,
  Grid,
  Divider,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
// import { findFlagUrlByIso2Code } from "country-flags-svg";
// import { updateProfile } from "../../api/userService";
import {
  changeUserLanguage,
  getRegionById,
  supportedLanguages,
} from "../../i18n";
import {
  setProfile,
  updatePreferredLanguage,
} from "../../redux/slices/authSlice";
import { setIsLanguageSelectorDialogOpen } from "../../redux/slices/languageSelectorDialogSlice";
import { RootState } from "../../redux/store";
import { remapPaymentMethods } from "../../types/paymentTypes";
// import { getVisits } from "../../api/visitsService";
import { setVisits, setVisitsLoading } from "../../redux/slices/visitsSlice";
import { VisitsResponse } from "../../types/common";
import { useErrorHandler } from "../../common/hooks";
import { AxiosError } from "axios";
import { useUpdateAParticipantPreferredLanguage } from "../../api/client/participant/participant";

const languageSelectedOnLoginKey = "languageSelectedOnLogin";

interface LanguageRegions {
  [region: string]: string[];
}

const languageRegions: LanguageRegions = {
  Europe: [
    "bg", // Bulgarian
    "cs", // Czech
    "da", // Danish
    "de", // German
    "et", // Estonian
    "fr", // French
    "it", // Italian
    "lt", // Lithuanian
    "nl", // Dutch
    "no", // Norwegian
    "pl", // Polish
    "pt", // Portuguese
    "ru", // Russian
    "es-ES", // Spanish (region-coded: Spain)
    "el", // Greek
    "hu", // Hungarian
    "ro", // Romanian
    "sk", // Slovak
    "tr", // Turkish
    "uk", // Ukrainian
  ],
  "Asia-Pacific": [
    "zh", // Chinese
    "ja", // Japanese
    "ko", // Korean
    "th", // Thai
    "vi", // Vietnamese
    "zf", // Traditional Chinese
  ],
  "Middle East and Africa": [
    "ar", // Arabic
    "he", // Hebrew
  ],
  Americas: [
    "en", // English
    "es", // Spanish (generic, covers Latin America)
  ],
};

const LanguageSelectorDialog: React.FC = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const { handleServerError } = useErrorHandler();
  const theme = useTheme();

  const isDialogOpen =
    useSelector((state: RootState) => state.languageSelectorDialog.isOpen) ||
    false;
  const isForcedSelection =
    useSelector((state: RootState) => state.languageSelectorDialog.isForced) ||
    false;
  const availableStudyLanguages =
    useSelector((state: RootState) => state.auth.languages) || [];
  const userPrefferedLanguage = useSelector(
    (state: RootState) =>
      state.auth.profile?.preferred_language?.attributes?.code
  );

  const browserLanguage = useMemo(() => {
    const parts = navigator.language.split("-");
    const language = parts[0].toLowerCase();

    return language;
  }, [navigator.language]);

  const isLoginPage = useMemo(() => {
    const currentUrl = window.location.href;

    if (currentUrl.indexOf("/login") > -1) {
      return true;
    }

    return false;
  }, [window.location.href]);

  function isStudyLanguage(lang: string): boolean {
    return getStudyLanguageByCode(lang) !== undefined;
  }

  function getStudyLanguageId(code: string) {
    return getStudyLanguageByCode(code)?.id;
  }

  function getStudyLanguageByCode(code: string) {
    return availableStudyLanguages.find((item) => {
      const itemLangCode = item.attributes.code;
      return itemLangCode === code;
    });
  }
  function getSupportedLanguageById(id: string) {
    return availableStudyLanguages.find((item) => {
      const itemLangId = item.id;
      return itemLangId === id;
    });
  }

  const handleLanguageSelectClick = (selectedLanguage: string) => {
    if (isLoginPage) {
      localStorage.setItem(languageSelectedOnLoginKey, selectedLanguage);
    } else {
      localStorage.removeItem(languageSelectedOnLoginKey);
    }

    handleLanguageSelect(selectedLanguage);
  };

  const {
    mutate: updatePreferredLanguageRequest,
    isPending: isLoading,
    isError,
    isSuccess,
  } = useUpdateAParticipantPreferredLanguage({
    mutation: {
      onError: (error, variables, context) => {
        // const errorMsg = getErrorMsg(t, error.errors);
        // setServerError(errorMsg);
      },
      onSuccess: (data, variables, context) => {
        const languageId = data.data?.relationships.language?.data?.id;
        if (!languageId)
          throw Error("Couldn't find the id of the selected language");

        const selectedLanguage = getSupportedLanguageById(languageId);
        if (!selectedLanguage)
          throw Error(
            "Couldn't find the selected language in supported languages"
          );
        dispatch(updatePreferredLanguage(selectedLanguage));
        hideDialog();
      },
    },
  });

  const handleLanguageSelect = async (selectedLanguage: string) => {
    changeUserLanguage(selectedLanguage);

    if (isLoginPage) {
      hideDialog();
    } else {
      const langId = getStudyLanguageId(selectedLanguage);
      if (!langId) return;
      updatePreferredLanguageRequest({
        data: {
          language: {
            data: {
              type: "languages",
              id: langId,
            },
          },
        },
      });
    }
  };

  function hideDialog() {
    dispatch(setIsLanguageSelectorDialogOpen({ isOpen: false }));
  }

  const filterLanguages = (regionLanguages: string[]): string[] => {
    if (isLoginPage) {
      const supportedLngs = Array.isArray(i18n.options.supportedLngs)
        ? i18n.options.supportedLngs
        : [];

      return regionLanguages.filter((langId) => supportedLngs.includes(langId));
    } else if (availableStudyLanguages.length > 0) {
      return regionLanguages.filter((langId) => isStudyLanguage(langId));
    }
    return regionLanguages;
  };

  interface Group {
    region: string;
    filteredLanguages: string[];
  }
  const renderLanguageByRegion = () => {
    const isGroup = (group: Group | null): group is Group => group !== null;

    const regionsWithLanguages: Group[] = Object.keys(languageRegions)
      .map((region) => {
        const filteredLanguages = filterLanguages(languageRegions[region]);

        if (filteredLanguages.length === 0) return null; // Skip empty regions

        return { region, filteredLanguages }; // Return region and its filtered languages
      })
      .filter(isGroup); // Remove null values (regions with no languages)

    const manyRegions = regionsWithLanguages.length > 1;
    // Render regions with languages
    return regionsWithLanguages.map(({ region, filteredLanguages }, index) => (
      <div key={region}>
        {index !== 0 && <Divider sx={{ margin: "16px 0" }} />}
        {manyRegions && (
          <Typography variant="h6" align="left" gutterBottom>
            {region}
          </Typography>
        )}
        <Grid container spacing={2}>
          {filteredLanguages.map((languageId) => {
            const language = getRegionById(languageId);
            if (!language) return null;

            return (
              <Grid item xs={12} sm={6} md={4} key={language.id}>
                <ListItem
                  sx={{
                    "&& .Mui-selected": {
                      backgroundColor: "transparent",
                      color: theme.palette.primary.main,
                    },
                    "&& .Mui-selected:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                  disablePadding
                  onClick={() => handleLanguageSelectClick(language.id)}
                >
                  <ListItemButton
                    selected={language.id === i18n.language}
                    sx={{
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <ListItemText
                      primary={language.name}
                      sx={{
                        "&:hover": {
                          textDecoration: "underline",
                          color: theme.palette.primary.main,
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </Grid>
            );
          })}
        </Grid>
      </div>
    ));
  };

  return (
    <Dialog
      dir={theme.direction}
      onClose={() => {}}
      open={isDialogOpen}
      sx={{
        "& .MuiDialog-paper": {
          width: "90%", // Full width on smaller screens
          maxWidth: "800px", // Max width for large screens
          margin: "0 auto", // Center it on larger screens
        },
      }}
    >
      <DialogTitle textAlign="center">
        {t("trialLanguage_title")}

        {!isForcedSelection && (
          <IconButton
            aria-label="close"
            onClick={() =>
              dispatch(setIsLanguageSelectorDialogOpen({ isOpen: false }))
            }
            sx={{
              position: "absolute",
              right: 8,
              top: 12,
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent style={{ minWidth: "300px", textAlign: "center" }}>
        {!isLoading && (
          <form>
            <List>{renderLanguageByRegion()}</List>
          </form>
        )}

        {isLoading && <CircularProgress />}
      </DialogContent>
    </Dialog>
  );
};

export default LanguageSelectorDialog;
