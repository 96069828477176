import type { Theme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    light: Palette["primary"];
  }

  interface PaletteOptions {
    light?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    light: true;
  }
}

export enum Client {
  Iqvia = "iqvia",
  Nmible = "nmible",
  Elligo = "elligo",
  Ich = "ich",
}

export type ClientTheme = {
  mui: Theme;
  favIcon: string;
  appBarColor: string;
  lightLogo: string;
  loginLogo: string;
  logoHeight: string;
  topBarLogoWidth: string;
  virtualCardBackgroundColor: string;
};
