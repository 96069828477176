import { Box, List, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  StipendJsonApiBlockRelationships,
  StipendJsonApiBlockWithId,
} from "../api/model/resources-stipends.yml";
import { MicropaymentJsonApiBlockWithId } from "../api/model/resources-micropayments.yml";
import { useAcceptStipendById } from "../api/model/stipends";
import ListItemCompound from "../components/ExpenseListItem2";
import { ExpenseRowData, formatISODate } from "../types/common";
import { useNavigate } from "react-router-dom";
import { useErrorHandler } from "../common/hooks";
import { useDispatch } from "react-redux";
import { showToast } from "../redux/slices/toastSlice";
import { useAcceptMicropaymentById } from "../api/client/micropayments/micropayments";
import { ParticipantVisitJsonApiBlockWithId } from "../api/model/resources-participant-visits.yml";

interface PendingStipendsListProps {
  dataType: "stipend" | "micropayment";
  data: (StipendJsonApiBlockWithId | MicropaymentJsonApiBlockWithId)[];
  completedTaxForm: boolean;
  participantVisits: ParticipantVisitJsonApiBlockWithId[];
}

const PendingStipendsList: React.FC<PendingStipendsListProps> = ({
  dataType,
  data,
  completedTaxForm,
  participantVisits,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [processingItem, setProcessingItem] = useState<string | null>(null);
  const { handleServerError } = useErrorHandler();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate: sendAcceptStipendRequest, isPending: acceptingStipend } =
    useAcceptStipendById({
      mutation: {
        onError: (error, variables, context) => {
          handleServerError(error);
        },
        onSuccess: (data, variables, context) => {
          queryClient.removeQueries({
            queryKey: ["/stipends"],
          });
          const id = data.data?.id;
          queryClient.removeQueries({
            queryKey: [`/stipends/${id}`],
          });
          dispatch(showToast(t("claim_stipend_approvedToastMessage")));
        },
        onSettled: (data, error, variables, context) => {
          setProcessingItem(null);
        },
      },
    });

  const {
    mutate: sendAcceptMicropaymentRequest,
    isPending: acceptingMicropayment,
  } = useAcceptMicropaymentById({
    mutation: {
      onError: (error, variables, context) => {
        handleServerError(error);
      },
      onSuccess: (data, variables, context) => {
        queryClient.removeQueries({
          queryKey: ["/micropayments"],
        });
        const id = data.data?.id;
        queryClient.removeQueries({
          queryKey: [`/micropayments/${id}`],
        });
        dispatch(showToast(t("claim_micropayment_approvedToastMessage")));
      },
      onSettled: (data, error, variables, context) => {
        setProcessingItem(null);
      },
    },
  });
  const handleAccept = async (id: string) => {
    setProcessingItem(id);

    if (dataType === "stipend") {
      sendAcceptStipendRequest({
        id: id,
        data: {
          data: {
            type: "stipends",
            id: id,
          },
        },
      });
    } else {
      sendAcceptMicropaymentRequest({
        id: id,
        data: {
          data: {
            type: "micropayments",
            id: id,
          },
        },
      });
    }
  };

  if (data.length === 0) {
    return null;
  }

  const sectionTitle = (title: string) => {
    return (
      <Typography
        sx={{
          mt: 2,
          mb: 1,
        }}
        variant="subtitle2"
        color="text.secondary"
        fontWeight="600"
      >
        {title}
      </Typography>
    );
  };

  return (
    <>
      {sectionTitle(
        t(
          dataType === "stipend"
            ? "claim_stipend_sectionTitle"
            : "claim_micropayment_sectionTitle"
        )
      )}

      <List>
        {data.map((stipend) => {
          var visitDate: string | undefined;
          if (
            "participantVisit" in stipend.relationships &&
            stipend.relationships.participantVisit
          ) {
            visitDate = participantVisits.find(
              (participantVisit) =>
                participantVisit.id ===
                (stipend.relationships as StipendJsonApiBlockRelationships)
                  .participantVisit?.data?.id
            )?.attributes?.actualVisitTimestamp;
          } else {
            visitDate = stipend.attributes.createdAt!;
          }

          const creationDate = visitDate
            ? formatISODate(visitDate, "date")
            : "";

          const listItemData: ExpenseRowData = {
            id: stipend.id!,
            createdAt: creationDate,
            type: dataType,
            amount: stipend.attributes.amount,
            status: stipend.attributes.status,
          };
          const isPending = listItemData.status.toLowerCase() === "pending";

          return (
            <ListItemCompound
              key={stipend.id!}
              onClick={() => {
                navigate(`/${dataType}/${stipend.id}`);
              }}
            >
              <ListItemCompound.Content>
                <ListItemCompound.Icon type={listItemData.type} />

                <ListItemCompound.Text>
                  <ListItemCompound.PrimaryText>
                    {`${t(listItemData.type)} - ${listItemData.id}`}
                  </ListItemCompound.PrimaryText>
                  <ListItemCompound.SecondaryText>
                    {listItemData.createdAt}
                  </ListItemCompound.SecondaryText>
                  <ListItemCompound.Badge status={t(listItemData.status)} />
                </ListItemCompound.Text>
              </ListItemCompound.Content>

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <ListItemCompound.TrailingContent>
                  ${listItemData.amount}
                </ListItemCompound.TrailingContent>

                {isPending && completedTaxForm && (
                  <ListItemCompound.Action
                    action={() => handleAccept(listItemData.id)}
                    executingAction={
                      (acceptingStipend &&
                        listItemData.type === "stipend" &&
                        processingItem === listItemData.id) ||
                      (acceptingMicropayment &&
                        listItemData.type === "micropayment" &&
                        processingItem === listItemData.id)
                    }
                  >
                    {t("accept")}
                  </ListItemCompound.Action>
                )}
              </Box>
            </ListItemCompound>
          );
        })}
      </List>
    </>
  );
};

export default PendingStipendsList;
