import { createTheme, type Theme } from "@mui/material/styles";
import favicon from "./icons/favicon.ico";
import lightLogo from "./images/light_logo.svg";
import loginLogo from "./images/login_logo.svg";

const primaryColor = "#0A3E45";
const secondaryColor = "#D90368";
const favIcon = favicon;
const appBarColor = "neutral.main";
const logoHeight = "30px";
const topBarLogoWidth = "70px";

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    light: {
      main: "#fff",
    },
    error: {
      main: "#e54142",
    },
    success: {
      main: "#2e7d32",
    },
    info: {
      main: "#0288d1",
    },
    divider: "rgba(0, 0, 0, 0.12)",
    background: {
      default: "#FAFAFA",
    },
  },
  shape: {
    borderRadius: 12,
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "rgba(0, 0, 0, 0.6)",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.Mui-error": {
            color: "#f44336",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          "& .MuiAlert-icon": {
            color: "inherit",
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          "& .MuiGrid-item": {
            maxWidth: "100%",
          },
        },
      },
    },
  },
});

export default {
  mui: theme,
  favIcon: favIcon,
  appBarColor: appBarColor,
  lightLogo: lightLogo,
  loginLogo: loginLogo,
  logoHeight: logoHeight,
  topBarLogoWidth: topBarLogoWidth,
  virtualCardBackgroundColor: primaryColor,
};
