//TOOD: Update
import {
  DirectionsCar as MileageIcon,
  Receipt as ReceiptIcon,
  Stars as RewardIcon,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactNode } from "react";

// Styled components
const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  alignItems: "flex-start",
  backgroundColor: "white",
  border: "1px solid #efefef",
  borderRadius: "8px",
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-between",
}));

// Main component
interface ListItemCompoundProps extends ListItemProps {
  children: ReactNode;
}

function ListItemCompound({ children, ...props }: ListItemCompoundProps) {
  return <StyledListItem {...props}>{children}</StyledListItem>;
}

// Icon subcomponent
ListItemCompound.Icon = function ListItemIcon({ type }: { type: string }) {
  const icon = (type: string) => {
    switch (type.toLowerCase()) {
      case "mileage":
        return <MileageIcon />;
      case "reward":
      case "stipend":
      case "micropayment":
        return <RewardIcon />;
      default:
        return <ReceiptIcon />;
    }
  };

  return (
    <ListItemAvatar>
      <Avatar>{icon(type.toLowerCase())}</Avatar>
    </ListItemAvatar>
  );
};

// Primary text subcomponent
ListItemCompound.PrimaryText = function PrimaryText({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Typography
      variant="subtitle1"
      component="div"
      sx={{ textTransform: "capitalize" }}
    >
      {children}
    </Typography>
  );
};

// Secondary text subcomponent
ListItemCompound.SecondaryText = function SecondaryText({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Typography variant="body2" color="text.secondary" component="div">
      {children}
    </Typography>
  );
};

ListItemCompound.Badge = function ListItemBadge({
  status,
}: {
  status: string;
}) {
  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "approved":
      case "accepted":
        return "success";
      case "rejected":
        return "error";
      default:
        return "warning";
    }
  };

  return (
    <Chip
      label={status}
      sx={{ marginTop: 1 }}
      color={getStatusColor(status)}
      size="small"
    />
  );
};

// Trailing content subcomponent
ListItemCompound.TrailingContent = function TrailingContent({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Typography variant="h6" component="div">
      {children}
    </Typography>
  );
};

// Content
ListItemCompound.Content = function Content({
  children,
}: {
  children: ReactNode;
}) {
  return <Box display="flex">{children}</Box>;
};

// Content
ListItemCompound.Text = function Text({ children }: { children: ReactNode }) {
  return (
    <Stack gap={0} direction={"column"}>
      {children}
    </Stack>
  );
};

// Action subcomponent
ListItemCompound.Action = function Action({
  action,
  executingAction,
  children,
}: {
  action: () => void;
  executingAction: boolean;
  children: ReactNode;
}) {
  return (
    <LoadingButton
      variant="contained"
      color="primary"
      size="medium"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        action();
      }}
      loading={executingAction}
    >
      {children}
    </LoadingButton>
  );
};

export default ListItemCompound;
