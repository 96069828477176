import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePageTitle } from "../../common/TitleContext";
import { useErrorHandler } from "../../common/hooks";
import TaxFormsList from "./TaxFormsList";
import TaxFormsSkeleton from "./TaxFormsSkeleton";
import TaxPreview, { DisplayMode } from "./TaxPreview";
import { useListAllTaxForms } from "../../api/client/tax-forms/tax-forms";
import { useTranslation } from "react-i18next";

const TaxPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  usePageTitle(t("tax"));
  const { data, isPending: loading, error } = useListAllTaxForms();

  const { handleServerError } = useErrorHandler();

  //   useEffect(() => {
  //     if (!error) return;
  //     handleServerError(error);
  //   }, [error]);

  if (loading) {
    return <TaxFormsSkeleton />;
  }

  if (error) return null;

  if (!data.data || data.data.length === 0)
    return (
      <TaxPreview
        mode={DisplayMode.fullpage}
        onContinue={() => {
          navigate("/tax/onboarding");
        }}
      ></TaxPreview>
    );
  return (
    <Box>
      <TaxFormsList data={data.data} />
    </Box>
  );
};

export default TaxPage;
